<template>
  <li class="accordion__item">
    <div
      class="accordion__trigger"
      :class="{ accordion__trigger_active: visible }"
      @click="open"
    >
      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <slot name="accordion-trigger"></slot>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion__content" v-show="visible">
        <ul>
          <!-- This slot will handle all the content that is passed to the accordion -->
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    },
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style lang="scss" scoped>
.accordion__item {
  cursor: pointer;
  padding: val(18) 0;
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  @include mobile {
    padding: valM(12) 0;
  }
  &.last,
  &:last-child {
    border-bottom: none;
  }
}
.accordion__content {
  ul {
    @include reset-list;
    padding-top: val(18);
    @include mobile {
      padding-top: valM(18);
    }
  }
}

.accordion__trigger {
  position: relative;
  padding-right: val(50);
  @include mobile {
    padding-right: valM(40);
  }
  &::before {
    position: absolute;
    top: 50%;
    right: val(10);
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5195 0v13.0062M18.0156 10.5008l-8.4982 8.4983-8.5154-8.4983' stroke='%233C3C3B' stroke-miterlimit='10'/%3E%3C/svg%3E");
    width: val(19);
    height: val(20);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: val(-10);
    content: "";
    transition: transform 0.3s ease;
    @include mobile {
      width: valM(11);
      height: valM(12);
      margin-top: valM(-6);
    }
  }
  &_active {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
