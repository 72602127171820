<template>
  <Title :text="t('t1')" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="image.path"
      :src-pic-mobile="image.mobile_path"
      :src-video="video.path"
      :src-video-mobile="video.mobile_path"
      :is-video="isVideoShow"
    />
  </div>

  <div class="grid-column grid-column_span_row_5">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_2">
    <div class="grid-inside">
      <div>
        <div class="title-big">QRCC</div>
        <p class="color-grey">Qatari-Russian Center for Cooperation</p>
      </div>
      <div v-if="isMobile" class="pm-42"></div>
      <div v-if="partners[0].events && partners[0].events.length > limit">
        <Button
          @click="partners[0].show = !partners[0].show"
          class="btn-default-grey"
          :msg="
            partners[0].show
              ? t('common_more_projects_hide')
              : t('common_more_projects')
          "
        />
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_span_2">
    <accordion>
      <accordion-item v-for="(item, index) in normalizeEvents0" :key="index">
        <template v-slot:accordion-trigger>
          <div class="h3 accordion__title">{{ item.title }}</div>
        </template>
        <template v-slot:accordion-content>
          <p class="color-grey" v-html="item.description"></p>
        </template>
      </accordion-item>
    </accordion>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column grid-column_span_2">
    <div class="grid-inside">
      <div>
        <div class="title-big">CCA</div>
        <p class="color-grey">Cultural Creative Agency</p>
      </div>
      <div v-if="isMobile" class="pm-42"></div>
      <div v-if="partners[1].events && partners[1].events.length > limit">
        <Button
          @click="partners[1].show = !partners[1].show"
          class="btn-default-grey"
          :msg="
            partners[1].show
              ? t('common_more_projects_hide')
              : t('common_more_projects')
          "
        />
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_span_2">
    <accordion>
      <accordion-item v-for="(item, index) in normalizeEvents1" :key="index">
        <template v-slot:accordion-trigger>
          <div class="h3 accordion__title">{{ item.title }}</div>
        </template>
        <template v-slot:accordion-content>
          <p class="color-grey" v-html="item.description"></p>
        </template>
      </accordion-item>
    </accordion>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column grid-column_span_2">
    <div class="grid-inside">
      <div>
        <div class="title-big">QRITA</div>
        <p class="color-grey">Qatari-Russian Investment and Trade Advisory</p>
      </div>
      <div v-if="isMobile" class="pm-42"></div>
      <div v-if="partners[2].events && partners[2].events.length > limit">
        <Button
          @click="partners[2].show = !partners[2].show"
          class="btn-default-grey"
          :msg="
            partners[2].show
              ? t('common_more_projects_hide')
              : t('common_more_projects')
          "
        />
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_span_2">
    <accordion>
      <accordion-item v-for="(item, index) in normalizeEvents2" :key="index">
        <template v-slot:accordion-trigger>
          <div class="h3 accordion__title">{{ item.title }}</div>
        </template>
        <template v-slot:accordion-content>
          <p class="color-grey" v-html="item.description"></p>
        </template>
      </accordion-item>
    </accordion>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column grid-column_span_2">
    <div class="grid-inside">
      <div>
        <div class="title-big">QRE</div>
        <p class="color-grey">QR Experience</p>
      </div>
      <div v-if="isMobile" class="pm-42"></div>
      <div v-if="partners[3].events && partners[3].events.length > limit">
        <Button
          @click="partners[3].show = !partners[3].show"
          class="btn-default-grey"
          :msg="
            partners[3].show
              ? t('common_more_projects_hide')
              : t('common_more_projects')
          "
        />
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_span_2">
    <accordion>
      <accordion-item v-for="(item, index) in normalizeEvents3" :key="index">
        <template v-slot:accordion-trigger>
          <div class="h3 accordion__title">{{ item.title }}</div>
        </template>
        <template v-slot:accordion-content>
          <p class="color-grey" v-html="item.description"></p>
        </template>
      </accordion-item>
    </accordion>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column grid-column_span_2">
    <div class="grid-inside">
      <div>
        <div class="title-big">QRS</div>
        <p class="color-grey">QR Sports</p>
      </div>
      <div v-if="isMobile" class="pm-42"></div>
      <div v-if="partners[4].events && partners[4].events.length > limit">
        <Button
          @click="partners[4].show = !partners[4].show"
          class="btn-default-grey"
          :msg="
            partners[4].show
              ? t('common_more_projects_hide')
              : t('common_more_projects')
          "
        />
        <div v-if="isMobile" class="grid-line"></div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_span_2">
    <accordion>
      <accordion-item v-for="(item, index) in normalizeEvents4" :key="index">
        <template v-slot:accordion-trigger>
          <div class="h3 accordion__title">{{ item.title }}</div>
        </template>
        <template v-slot:accordion-content>
          <p class="color-grey" v-html="item.description"></p>
        </template>
      </accordion-item>
    </accordion>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import { GetProjects } from "@/api/Pages";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Button from "@/components/Button.vue";
import Share from "@/components/Share.vue";
import Accordion from "@/components/Accordion.vue";
import AccordionItem from "@/components/AccordionItem.vue";

function normalizeEvents(partners, index, limit) {
  if (
    partners[index] &&
    partners[index].events &&
    partners[index].events.length
  ) {
    if (partners[index].show) {
      return partners[index].events;
    }
    return partners[index].events.slice(0, limit);
  }
  return [];
}

export default defineComponent({
  name: "Projects",
  components: {
    Title,
    Promo,
    Button,
    Share,
    Accordion,
    AccordionItem,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  computed: {
    normalizeEvents0() {
      return normalizeEvents(this.partners, 0, this.limit);
    },
    normalizeEvents1() {
      return normalizeEvents(this.partners, 1, this.limit);
    },
    normalizeEvents2() {
      return normalizeEvents(this.partners, 2, this.limit);
    },
    normalizeEvents3() {
      return normalizeEvents(this.partners, 3, this.limit);
    },
    normalizeEvents4() {
      return normalizeEvents(this.partners, 4, this.limit);
    },
  },
  data() {
    return {
      limit: 6,
      partners: [{}, {}, {}, {}, {}],
      video: {},
      image: {},
      isVideoShow: false,
    };
  },
  watch: {
    locale() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const resp = await GetProjects();
        const {
          partners = [{}, {}, {}, {}, {}],
          image = {},
          show_video = false,
          video = {},
        } = resp.data.data;

        this.partners = partners;
        this.isVideoShow = show_video;
        this.video = video;
        this.image = image;
        this.$progress.finish();
      } catch (error) {
        this.$progress.finish();
        console.log(error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@include mobile {
  .title-big {
    font-size: valM(60);
  }
  .accordion__title {
    font-size: valM(16);
  }
}
</style>

<i18n>
{
  "en": {
    "t1": "Our projects",
  },
  "ru": {
    "t1": "Наши проекты",
  },
  "ar": {
    "t1": "مشاريعنا",
  }
}
</i18n>
