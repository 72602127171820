<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      Accordion: {
        count: 0,
        active: null,
      },
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  @include reset-list;
  margin: val(-9) 0;
  @include mobile {
    margin: valM(-15) 0;
  }
}
</style>
